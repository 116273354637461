@import 'react-toastify/dist/ReactToastify.css';
@import './assets/css/react-datepicker.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-black text-white;
  }

  label {
    @apply block text-sm mb-2;
  }
  label.required::after {
    content: '*';
    @apply text-gold-500 pl-1;
  }
  input.tw-control,
  textarea.tw-control,
  select.tw-control {
    @apply w-full text-white p-2 rounded-lg outline-0 border-2 border-transparent focus:border-gold-500 placeholder:text-muted placeholder:text-sm bg-input disabled:bg-input-disabled disabled:text-disabled disabled:border-input disabled:cursor-not-allowed;
  }
  input[type='checkbox'].tw-control,
  input[type='radio'].tw-control {
    @apply w-4 h-4 accent-gold-500 rounded-lg;
  }

  select.tw-control option {
    @apply text-white bg-input;
  }
  .placeholder-text {
    @apply text-muted;
  }
  .listbox {
    @apply w-full text-white bg-input p-2 rounded-lg text-left;
  }
  .drop-zone {
    @apply bg-input text-center overflow-hidden rounded-lg border-4 border-dashed border-gold-500 cursor-pointer hover:border-gold-400;
  }
  /* select:required:invalid {
    color: gray;
  }
  option[value=""][disabled] {
    display: none;
    @apply text-muted
  }
  option {
    color: black;
  } */
  a {
    @apply text-gold-400 hover:text-gold-500 duration-500;
  }
  .text-primary {
    @apply text-gold-500;
  }
  .company-nav a {
    @apply hover:text-muted;
  }
  .company-nav a.active {
    @apply text-white;
  }
  button,
  .btn {
    @apply py-3 px-8 rounded-lg duration-300 outline-0 border-2 border-transparent text-inherit;
  }

  button.primary,
  .btn-primary {
    @apply bg-gold-500 disabled:bg-input disabled:cursor-not-allowed hover:text-white hover:bg-gold-600  focus:border-gold-300;
  }
  button.back,
  .btn-back {
    @apply text-muted hover:text-white px-0;
  }
  button.secondary,
  .btn-secondary {
    @apply bg-input hover:border-muted focus:border-muted;
  }
  button.danger,
  .btn-danger {
    @apply bg-red-500 disabled:bg-input disabled:cursor-not-allowed hover:text-white hover:bg-red-600  focus:border-red-300;
  }
  .bg-primary {
    @apply bg-gold-500;
  }
  button:disabled {
    @apply cursor-not-allowed;
  }
  input.invalid,
  textarea.invaid,
  select.invalid {
    @apply outline-0 border-2 border-red-300;
  }
  .invalid-msg {
    @apply text-red-300 text-xs mt-1;
  }
  .react-datepicker {
    @apply bg-input;
  }
  .react-datepicker select {
    @apply border-gold-500 my-2;
  }
  .bg-success {
    @apply bg-green-700;
  }
  .bg-info {
    @apply bg-indigo-600;
  }
  .bg-warning {
    @apply bg-yellow-600;
  }
  .bg-danger {
    @apply bg-red-400;
  }
  .text-danger {
    @apply text-red-300;
  }

  .dropdown {
    @apply relative;
  }
  .dropdown-menu {
    @apply absolute block bg-black border-gold-500 border-2 p-1 shadow-md mt-4 w-56 text-left right-0 rounded-md;
    &:before {
      @apply absolute -mt-3 right-4 w-0 h-0 border-x-8 border-x-transparent border-b-8 border-b-gold-500 content-[""];
    }
  }
  /* .dropdown-menu li {
    @apply block w-full
  } */
  .dropdown-menu li a {
    @apply p-2 block;
  }

  .react-datepicker select {
    @apply bg-input p-2 rounded-lg outline-0 border-2 border-transparent focus:border-gold-500 placeholder:text-muted placeholder:text-sm;
  }

  .dot:not(:empty):before {
    content: '·';
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #ffffff;
    position: relative;
  }
}

@layer components {
  /* @apply w-full text-white p-2 rounded-lg outline-0 border-2 border-transparent focus:border-gold-500 placeholder:text-muted placeholder:text-sm bg-input disabled:bg-input-disabled disabled:border-input disabled:cursor-not-allowed;
   */
  .tw-react-select-container .tui-react-select__control {
    @apply text-white bg-input border-2 border-transparent rounded-lg hover:border-transparent disabled:bg-input-disabled disabled:border-input disabled:cursor-not-allowed;
  }
  .tw-react-select-container .tui-react-select__control--is-focused {
    @apply border-gold-500 hover:border-gold-500 shadow-none;
  }

  .tw-react-select-container .tui-react-select__menu {
    @apply text-white bg-input border-0;
  }

  .tw-react-select-container .tui-react-select__option {
    @apply text-white bg-input hover:bg-gold-500;
  }
  .tw-react-select-container .tui-react-select__option--is-focused {
    @apply bg-input;
  }

  /* .tw-react-select-container .tui-react-select__indicator-separator {
    @apply hidden;
  } */

  .tw-react-select-container .tui-react-select__input-container,
  .tw-react-select-container .tui-react-select__placeholder,
  .tw-react-select-container .tui-react-select__single-value {
    @apply text-white placeholder:text-muted placeholder:text-sm;
  }
}
